import Container from "@material-ui/core/Container";
import * as React from "react"
import { Layout } from "../components/layout";
import { PLTable } from "../components/pl-table";

const PLTablePage: React.FC = () => {
  return (
    <Layout>
      <Container>
        <h1>Premier league stillingen</h1>
        <PLTable />
      </Container>
    </Layout>
  )
}

export default PLTablePage;
